import React, { useState } from "react";
import "../styles/FromStyle.css";
import SibApiV3Sdk from "sib-api-v3-sdk";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); // Nuevo estado para controlar el envío exitoso

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) newErrors.name = "Name is required.";
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required.";
    } else if (!/^[0-9]{10,11}$/.test(formData.phone)) {
      newErrors.phone = "Enter a valid 11-digit phone number.";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Enter a valid email address.";
    }

    if (!formData.message.trim()) {
      newErrors.message = "Message is required.";
    }

    return newErrors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const sendEmailCampaign = async () => {
    var defaultClient = SibApiV3Sdk.ApiClient.instance;
    var apiKey = defaultClient.authentications["api-key"];
    apiKey.apiKey = "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";

    var apiInstance = new SibApiV3Sdk.EmailCampaignsApi();
    var emailCampaigns = new SibApiV3Sdk.CreateEmailCampaign();

    emailCampaigns.name = `Message from ${formData.name}`;
    emailCampaigns.subject = "Contact Form Submission";
    emailCampaigns.sender = { name: "selectTMS", email: "epm@bavanapartners.com" };
    emailCampaigns.type = "classic";

    emailCampaigns.htmlContent = `
      <h1>New Contact Form Submission</h1>
      <p><strong>Name:</strong> ${formData.name}</p>
      <p><strong>Phone:</strong> ${formData.phone}</p>
      <p><strong>Email:</strong> ${formData.email}</p>
      <p><strong>Message:</strong> ${formData.message}</p>
    `;
    emailCampaigns.recipients = { listIds: [3]};
    emailCampaigns.scheduledAt = new Date().toISOString();

    await apiInstance.createEmailCampaign(emailCampaigns);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
  
    setIsSubmitting(true);
  
    try {
      await sendEmailCampaign();
      setIsSubmitted(true); // Cambia el estado a enviado con éxito
    } catch (error) {
      console.error("Error sending email:", error);
      alert("There was an error sending your message.");
    } finally {
      setIsSubmitting(false);
      setFormData({ name: "", phone: "", email: "", message: "" });
      setErrors({});
    }
  };

  if (isSubmitted) {
    return (
      <div className="success-message">
        <h2>All set! Our team will get in contact with you soon.</h2>
      </div>
    );
  }

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit} className="form-content">
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="form-input"
            placeholder="Enter your name"
          />
          {errors.name && <span className="error-message">{errors.name}</span>}
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Phone Number</label>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              className="form-input"
              placeholder="Enter your phone number"
            />
            {errors.phone && <span className="error-message">{errors.phone}</span>}
          </div>

          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="form-input"
              placeholder="Enter your email"
            />
            {errors.email && <span className="error-message">{errors.email}</span>}
          </div>
        </div>

        <div className="form-group">
          <label>Message</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            className="form-textarea"
            placeholder="Enter your message"
          />
          {errors.message && <span className="error-message">{errors.message}</span>}
        </div>

        <button type="submit" className="button-From" disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
